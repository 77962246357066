import React, {useEffect} from 'react';
// eslint-disable-next-line node/no-extraneous-import
import {
  BottomNavigation,
  BottomNavigationAction,
  bottomNavigationActionClasses,
  buttonClasses,
  CssBaseline,
  svgIconClasses,
  SvgIconProps,
  Theme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import * as RDS from '@verily-src/react-design-system';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import {Observable, Subscription} from 'rxjs';
import {navigateToUrl} from 'single-spa';
import NavApi, {
  HeaderSlotItem,
  NavItem,
  NavItemGroup,
  NavProps,
} from './api/Nav';
import {HeaderSlot} from './components/HeaderSlot/HeaderSlot';
import {shouldNavBeVisibleForPath} from './utils';

function isNavItemGroups(
  navItems: (NavItem | NavItemGroup)[]
): navItems is NavItemGroup[] {
  return Boolean(
    navItems.length && (navItems[0] as NavItemGroup).items !== undefined
  );
}

function rdsChildNavItemsFromAPIItems(
  navItems: NavItem[]
): Omit<RDS.UnnestedNavItem, 'icon'>[] {
  const rdsChildNavItems: Omit<RDS.UnnestedNavItem, 'icon'>[] = [];

  navItems.forEach((navItem) => {
    rdsChildNavItems.push({
      name: navItem.name,
      path: navItem.path ?? '',
    });
  });

  return rdsChildNavItems;
}

export function getRDSLogo(id: string, logoProps?: SvgIconProps) {
  if (!id) return <></>;

  return RDS[id as keyof typeof RDS] ? (
    // Have to disable the typescript check on getting logos from RDS.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.createElement(RDS[id], logoProps)
  ) : (
    <></>
  );
}

/** @internal */
export function rdsNavItemsFromApiItems(navItems: NavItem[]): RDS.NavItem[] {
  const rdsNavItems: RDS.NavItem[] = [];

  navItems.forEach((navItem: NavItem) => {
    if (navItem.path) {
      // Treat nav item as top level -- following greedy
      // approach outlined in RDS.
      rdsNavItems.push({
        name: navItem.name,
        path: navItem.path,
        icon: getRDSLogo(navItem.icon),
        selectedIcon: getRDSLogo(
          navItem.selectedIcon ? navItem.selectedIcon : navItem.icon
        ),
      });
    } else if (navItem.children !== undefined) {
      rdsNavItems.push({
        name: navItem.name,
        icon: getRDSLogo(navItem.icon),
        selectedIcon: getRDSLogo(
          navItem.selectedIcon ? navItem.selectedIcon : navItem.icon
        ),
        children: rdsChildNavItemsFromAPIItems(navItem.children),
      });
    } else {
      throw Error('Nav item ill-formed. Needs path or children defined.');
    }
  });

  return rdsNavItems;
}

/** @internal */
export function rdsNavItemGroupsFromAPIGroups(navItemGroups: NavItemGroup[]) {
  const rdsNavItemGroups: RDS.NavItemGroup[] = [];

  navItemGroups.forEach((navItemGroup) => {
    const rdsNavItemGroup: RDS.NavItemGroup = {
      name: navItemGroup.name,
      items: rdsNavItemsFromApiItems(navItemGroup.items),
    };

    rdsNavItemGroups.push(rdsNavItemGroup);
  });

  return rdsNavItemGroups;
}

type SubjectHandler = {
  /** The source of items from the Nav API. */
  itemSource$: Observable<Array<NavItem | NavItemGroup>>;
  /** The most recently received items from the Nav API. */
  currentItems: Array<NavItem | NavItemGroup>;
  /** Update current items with the new items from the Nav API. */
  setCurrentItems: React.Dispatch<
    React.SetStateAction<Array<NavItem | NavItemGroup>>
  >;
  /** Update the RDS item passed to the Nav component. */
  setRdsItems: React.Dispatch<
    React.SetStateAction<Array<RDS.NavItem | RDS.NavItemGroup>>
  >;
};

/**
 * By default the RDS EnterpriseLightTheme is used if none provided.
 */
function Root(props: NavProps) {
  const {
    logo = undefined,
    showDividers = undefined,
    isResponsive = undefined,
    theme,
    themeName,
    locale,
    homePath = '/',
    logoutCallback = undefined,
    logoProps = {},
  } = props;

  const logoutIcon = logoutCallback
    ? React.createElement(RDS['LogoutIcon'])
    : undefined;

  const lookedUpLogo = getRDSLogo(logo, logoProps);
  const lookedUpTheme = RDS.verilyThemeLookup(themeName);

  // Watch for nav item updates.
  const [navItems, setNavItems] = React.useState<RDS.NavItem[]>([]);
  const [currentNavItems, setCurrentNavItems] = React.useState<NavItem[]>([]);
  const [navIsVisible, setNavIsVisible] = React.useState<boolean>(true);
  const navItemsSource$: Observable<NavItem[]> = NavApi.items;

  // Watch for nav item group updates.
  const [navItemGroups, setNavItemGroups] = React.useState<RDS.NavItemGroup[]>(
    []
  );
  const [currentNavItemGroups, setCurrentNavItemGroups] = React.useState<
    NavItemGroup[]
  >([]);
  const navItemGroupsSource$: Observable<NavItemGroup[]> = NavApi.itemGroups;

  // Watch for nav footer item updates.
  const [navFooterItems, setNavFooterItems] = React.useState<RDS.NavItem[]>([]);
  const [currentNavFooterItems, setCurrentNavFooterItems] = React.useState<
    NavItem[]
  >([]);
  const navFooterItemsSource$: Observable<NavItem[]> = NavApi.footerItems;

  // Watch for nav header slot updates.
  const [navHeaderSlotItem, setNavHeaderSlotItem] =
    React.useState<HeaderSlotItem>();
  const navHeaderSlotItemSource$: Observable<HeaderSlotItem> =
    NavApi.headerSlotItem;

  const [navOpen, setNavOpen] = React.useState(true);

  const isDesktop = useMediaQuery('(min-width:768px)');

  const themeToUse = lookedUpTheme ?? theme ?? RDS.EnterpriseLightTheme;

  useEffect(() => {
    const subjectHandlers: SubjectHandler[] = [
      {
        itemSource$: navItemsSource$,
        currentItems: currentNavItems,
        setCurrentItems: setCurrentNavItems,
        setRdsItems: setNavItems,
      },
      {
        itemSource$: navItemGroupsSource$,
        currentItems: currentNavItemGroups,
        setCurrentItems: setCurrentNavItemGroups,
        setRdsItems: setNavItemGroups,
      },
      {
        itemSource$: navFooterItemsSource$,
        currentItems: currentNavFooterItems,
        setCurrentItems: setCurrentNavFooterItems,
        setRdsItems: setNavFooterItems,
      },
    ];

    // Register subscriptions.
    const subscriptions: Subscription[] = subjectHandlers.map(
      ({
        itemSource$,
        currentItems,
        setCurrentItems,
        setRdsItems,
      }): Subscription => {
        return itemSource$.subscribe({
          next: (newItems: (NavItem | NavItemGroup)[]) => {
            // Compare the new subject value with the subject dependency to
            // determine if value contents has changed.
            if (!isEqual(newItems, currentItems)) {
              // Update subject reference.
              setCurrentItems(cloneDeep(newItems));

              // Check if subject value appears to be of form NavItemGroup[].
              if (isNavItemGroups(newItems)) {
                setRdsItems(rdsNavItemGroupsFromAPIGroups(newItems));
              } else {
                // newItems appears to be of NavItem[] type.
                setRdsItems(rdsNavItemsFromApiItems(newItems));
              }
            }
          },
        });
      }
    );
    const headerSlotSubscription = navHeaderSlotItemSource$.subscribe({
      next: (newItem: HeaderSlotItem) => {
        if (isEqual(newItem, navHeaderSlotItem) || !newItem) {
          return;
        }
        setNavHeaderSlotItem(newItem);
      },
    });
    subscriptions.push(headerSlotSubscription);

    return () => {
      // On unmount clear all subscriptions.
      subscriptions.forEach((subscription: Subscription) =>
        subscription.unsubscribe()
      );
    };
  }, [
    navItemsSource$,
    navItems,
    navItemGroupsSource$,
    navItemGroups,
    navFooterItemsSource$,
    navFooterItems,
    navHeaderSlotItemSource$,
    navHeaderSlotItem,
  ]);

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<
    number | undefined
  >();

  const listenToPopstate = () => {
    const activePath = window.location.pathname;
    const activeIndex = navItems.findIndex((item) =>
      activePath.startsWith((item as RDS.UnnestedNavItem).path)
    );
    setSelectedTabIndex(activeIndex === -1 ? undefined : activeIndex);

    const explicitHideNavParam = new URLSearchParams(
      window.location.search
    ).get('hideNav');
    if (explicitHideNavParam === 'true') {
      setNavIsVisible(false);
    } else if (explicitHideNavParam === 'false') {
      setNavIsVisible(true);
    } else {
      const navShouldBeVisible = shouldNavBeVisibleForPath(
        activePath,
        props.hideOn
      );
      setNavIsVisible(navShouldBeVisible);
    }
  };
  React.useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    listenToPopstate();
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, [navItems]);

  if (!navIsVisible) {
    return <></>;
  }

  return (
    <ThemeProvider
      theme={{
        ...themeToUse,
        locale: locale ?? themeToUse.locale,
      }}
    >
      <CssBaseline />
      {isResponsive && !isDesktop ? (
        <BottomNavigation
          role="tablist"
          showLabels
          value={selectedTabIndex}
          sx={{
            background: (theme) => theme.palette.background.canvas,
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: (theme: Theme) =>
              theme.palette.background.separator,
            display: 'flex',
            width: '100%',
            height: '74px', // default 56px + 18px bottom padding
            paddingBottom: '18px',
            justifyContent: 'space-evenly',
          }}
        >
          {navItems &&
            navItems.map((navItem, tabIndex) => {
              const unnestedNavItem = navItem as RDS.UnnestedNavItem;
              const selected = selectedTabIndex === tabIndex;
              return (
                <BottomNavigationAction
                  pendo-id={unnestedNavItem.path.replace('/', '_')}
                  key={tabIndex}
                  role="tab"
                  aria-selected={selected}
                  label={unnestedNavItem.name}
                  icon={
                    selected && unnestedNavItem.selectedIcon
                      ? unnestedNavItem.selectedIcon
                      : unnestedNavItem.icon
                  }
                  onClick={() => navigateToUrl(unnestedNavItem.path)}
                  sx={{
                    borderColor: (theme: Theme) => theme.palette.primary.main,
                    boxSizing: 'border-box',
                    '&:focus-visible': {
                      border: '2px solid',
                    },
                    [`& .${svgIconClasses.root} path`]: {
                      fill: (theme: Theme) => theme.palette.icon.muted,
                    },
                    [`& .${bottomNavigationActionClasses.label}`]: {
                      color: (theme: Theme) => theme.palette.text.muted,
                    },
                    [`&.${bottomNavigationActionClasses.selected}`]: {
                      [`.${svgIconClasses.root} path`]: {
                        fill: (theme: Theme) => theme.palette.icon.default,
                      },
                      [`.${bottomNavigationActionClasses.label}`]: {
                        color: (theme: Theme) => theme.palette.text.emphasized,
                      },
                    },
                  }}
                />
              );
            })}
        </BottomNavigation>
      ) : (
        <RDS.Nav
          homePath={homePath}
          showDividers={showDividers}
          navItems={navItems}
          navItemGroups={navItemGroups}
          navFooterItems={navFooterItems}
          navigateToUrl={navigateToUrl}
          homeLogo={lookedUpLogo && lookedUpLogo}
          footerSlot={
            logoutCallback && (
              <RDS.Button
                variant="text"
                sx={{
                  width: '100%',
                  color: (theme: Theme) => theme.palette.text.default,
                  '.MuiTypography-button1': {
                    fontSize: '.875rem',
                  },
                  [`&.${buttonClasses.root}`]: {
                    pl: '.875rem',
                    justifyContent: 'left',
                  },
                  '.MuiSvgIcon-root': {
                    mr: '.5rem',
                  },
                  '&:hover, &:focus-visible': {
                    outline: 'none',
                    backgroundColor: (theme: Theme) =>
                      theme.palette.neutral.background,
                  },
                  '&:active': {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.primary.background,
                  },
                }}
                icon={logoutIcon}
                label="Logout"
                onClick={logoutCallback}
              />
            )
          }
          headerSlot={
            <HeaderSlot headerSlot={navHeaderSlotItem} navOpen={navOpen} />
          }
          onCollapseToggle={setNavOpen}
        />
      )}
    </ThemeProvider>
  );
}

export default Root;
