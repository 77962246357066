import {HideOn} from './api/Nav';

export function shouldNavBeVisibleForPath(path: string, hideOns?: HideOn[]) {
  for (const hideOn of hideOns || []) {
    if (
      hideOn.isPrefix &&
      path.trim().toLowerCase().startsWith(hideOn.path.trim().toLowerCase())
    ) {
      return false;
    }

    if (
      !hideOn.isPrefix &&
      path.trim().toLowerCase() === hideOn.path.trim().toLowerCase()
    ) {
      return false;
    }
  }

  return true;
}
