// Need to hoist public path setting to top so ES6 imports do not come before it.
import './publicPath';
// This is used to expose PHAF global declarations.
import '@verily-src/phaf-runtime-helpers';

// Need to hoist public path setting to top so ES6 imports do not come before it.
import React, {ComponentClass, FunctionComponent} from 'react';
import ReactDOM from 'react-dom';
import {AppProps} from 'single-spa';
import singleSpaReact from 'single-spa-react';
import Nav, {
  type NavItem,
  type NavItemGroup,
  type NavProps,
  type NavTestAPI,
} from './api/Nav';
import reportWebVitals from './reportWebVitals';
import Root from './root.component';

type RootProps = {
  nav: NavProps;
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: (props: RootProps & AppProps) =>
    new Promise<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | ComponentClass<RootProps & AppProps, any>
      | FunctionComponent<RootProps & AppProps>
    >((resolve) => resolve(() => <Root {...props.nav} />)),
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return <div>An unexpected error has occurred.</div>;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

export {Nav, NavProps, NavItem, NavItemGroup, NavTestAPI};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
